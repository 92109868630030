import '@styles/portal.sass'

import {createApp, reactive} from "petite-vue"
import initSearch from "./forms/search";
import initArchive from "./forms/archive";
import initMap from "./components/map";
import {isInViewport} from "./utils/is-in-viewport";
import {getCookie, setCookie} from "./utils/cookies";
import {toggleFavoriteOffer} from "./utils/offer";

declare global {
    const localization: Localization
    const Weglot: WeglotObject
    const searchConfig: SearchConfig
    const filterData: FilterData
    const archiveData: ArchiveData
    const dataLayer: object[]
    const offerAnalyticalData: {}
}

initSearch()
initArchive()

createApp({
    favoriteOffer(e: Event) {
        const target = e.target as HTMLElement
        target.matches('[data-favorite-offer]') && toggleFavoriteOffer(target)
    },
    copyLinkTimeout: false,
    copyOfferLink() {
        const url = `${window.location.origin}${window.location.pathname}`

        navigator.clipboard.writeText(url).then(() => {
            this.copyLinkTimeout = true
            setTimeout(() => this.copyLinkTimeout = false, 20000)
        }, () => {
            console.error('Nepovedlo se zapsat URL do clipboardu. :-(')
        })
    },
    canShare: navigator.canShare,
    async shareOffer() {
        try {
            await navigator.share({ title: document.title, url: location.href })
        } catch (err) {
            console.error(err);
        }
    }
}).mount('[data-offer]')

initMap();

(() => {
    const popup = document.querySelector('[data-call-us-popup]') as HTMLDetailsElement
    if (!popup) return

    const footer = document.querySelector('footer'),
        scrollTopBtn = document.querySelector('[data-scroll-top]')
    const toggleFloatingsVisibility = () => {
        if (!popup.hasAttribute('data-open'))
            popup.open = !isInViewport(footer, 8)

        scrollTopBtn?.toggleAttribute('disabled', window.scrollY < 480
            || isInViewport(footer, 0))
    }

    popup.querySelector('summary')?.addEventListener('click', () => {
        const state = String(!popup.open)
        popup.setAttribute('data-open', state)
        window.localStorage.setItem('call-popup-opened', state)
    })

    if (window.localStorage.getItem('call-popup-opened'))
        popup.setAttribute('data-open', window.localStorage.getItem('call-popup-opened'))

    toggleFloatingsVisibility()
    window.addEventListener('scroll', () => toggleFloatingsVisibility(), { passive: true })

    scrollTopBtn?.addEventListener('click', () => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }))
})();

(() => { // Last viewed offers
    if (!document.body.classList.contains('single-offer'))
        return

    const offerID = Array.from(document.body.classList)
        .find(el => el.startsWith('postid-'))
        .replace('postid-', '')

    let cookieArray = []
    if (getCookie('lastViewedOffers') !== '')
        cookieArray = getCookie('lastViewedOffers').split(';')

    cookieArray = cookieArray.filter(offer => offer !== offerID)
    cookieArray.unshift(offerID)

    setCookie('lastViewedOffers', cookieArray.splice(0, 4).join(';'), 365)
})();


(() => {
    // @ts-ignore
    window.hasOwnProperty('html_forms') && html_forms.on('error', (formElement: HTMLFormElement) => {
        formElement.classList.add('has-error', 'is-processing')
        const checkForError = setInterval(() => {
            const error = formElement.querySelector<HTMLElement>('.hf-message')
            if (!error) return

            if (error.innerText === 'gdpr_forbidden') {
                error.remove();
                formElement.querySelector<HTMLButtonElement>('[type="submit"]').disabled = true;
                formElement.addEventListener("submit", (e) => { e.preventDefault() });

                const modal = document.createElement('dialog');
                modal.classList.add('Modal', 'Modal--narrow');
                modal.innerHTML = '<div class="ModalInner"><div class="ModalTitle u-textMd">Oznámenie</div><small>Vážený uchádzač,<br>na základe Vašich predchádzajúcich žiadostí o&nbsp;výmaz osobných údajov sme nemohli prijať Vašu žiadosť o&nbsp;zaradenie do&nbsp;výberového konania. Ak si želáte byť napriek tomu zaradený do&nbsp;výberového konania, prosím kontaktujte nás na telefónnom čísle <a href="tel:0800189099">0800&nbsp;189&nbsp;099</a>.<br><br>S pozdravom,<br>Tím Manuvia</small></div>';
                document.body.insertAdjacentElement('beforeend', modal);
                modal.showModal();
            }

            formElement.classList.remove('is-processing')
            clearInterval(checkForError)
        }, 200)
    })
})();
